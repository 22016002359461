import React from 'react'
import { Helmet } from "react-helmet"
import { Link, graphql } from 'gatsby'
import Img from "gatsby-image"
import Seo from '../components/seo'
import NavMobile from '../components/nav_mobile'
import BackgroundImage from 'gatsby-background-image'
import GoogleReviews from '../components/google_reviews_slider'
import Copyright from '../components/copyright'

export default ({ data }) => {
    const entry = data.markdownRemark
    return (
        <div className="page">
            <Helmet>
                <link href="https://fonts.googleapis.com/css2?family=Oswald&display=swap" rel="stylesheet" />
            </Helmet>
            <Seo title={entry.frontmatter.page_title} description={entry.frontmatter.meta_description} image="" pathname={entry.fields.slug} />
            <NavMobile />
            <div className="layout">
                <BackgroundImage fluid={entry.frontmatter.image.childImageSharp.fluid} backgroundColor={`#ffffff`}>
                    <div className="title__gradient">
                        <div className="layout__container">
                            <div className="layout__wide title center">
                                <h1>{entry.frontmatter.heading}</h1>
                                <p>Phone us for a quick response and fast quote.<br />Call us now on <a href="tel:0800828299" title="0800 8282 99" rel="nofollow noopener">0800 8282 99</a>.</p>
                            </div>
                        </div>
                    </div>
                </BackgroundImage>
            </div>
            <div className="layout grey">
                <div className="layout__container">
                    <div className="layout__wide">
                        <nav className="breadcrumb">
                            <p><Link to='/'>Home</Link> &nbsp;&rsaquo;&nbsp; <strong>{entry.frontmatter.title}</strong></p>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="layout body">
                <div className="layout__container">
                    <div className="layout__wide">
                        <div className="grid grid__2-1 grid__gap--48 text">
                            <div>
                                <div dangerouslySetInnerHTML={{ __html: entry.html }} />
                                <Link to="/contact" title="Contact" className="btn btn__large btn__margin">Request quote &nbsp;►</Link>
                            </div>
                            <div className="aside">
                                <Img fluid={entry.frontmatter.side_image.childImageSharp.fluid} alt={entry.frontmatter.side_heading} />
                                <h3>{entry.frontmatter.side_heading}</h3>
                                <ul>
                                    {entry.frontmatter.side_text.map( (entry, i) => (
                                        <li key={i}>{entry.text}</li>
                                    ))}
                                </ul>
                                <figure className="partners">
                                    <a href="https://www.topreviews.co.nz/best-garage-door-repair-auckland/#7_Eden_Garage_Doors" title="Top Reviews" rel="nofollow noopener"><Img fixed={data.top_reviews.childImageSharp.fixed} alt='Top Reviews' /></a>
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <GoogleReviews />
            <Copyright />
        </div>
    )
}

export const query = graphql`
	query($slug: String!) {
        top_reviews: file(relativePath: { eq: "top_reviews.jpg" }) {
            childImageSharp {
                fixed(width: 150) {
                    ...GatsbyImageSharpFixed
                  }
            }
        }
		markdownRemark( fields: { slug: { eq: $slug } }) {
			frontmatter {
                section
                title
				page_title
                meta_description
                heading
                image {
                    childImageSharp {
                        fluid(maxWidth: 1280) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                side_heading
                side_text {
                    text
                }
                side_image {
                    childImageSharp {
                        fluid(maxWidth: 640, maxHeight: 480) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
			}
			fields {
				slug
			}
			html
		}
	}
`